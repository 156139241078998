.loader0 {

  background: url("../../images/iconos/04-Brazil\ flag.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader1 {

  background: url("../../images/iconos/02-Brazil.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader2 {

  background: url("../../images/iconos/05-Toucan.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader3 {

  background: url("../../images/iconos/09-Drum.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader4 {

  background: url("../../images/iconos/12-redeemer.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader5 {

  background: url("../../images/iconos/13-Coffee.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader6 {

  background: url("../../images/iconos/17-Brazilian real.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader7 {

  background: url("../../images/iconos/25-Palm tree.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}