@import url(https://fonts.googleapis.com/css?family=Bangers&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script|Roboto);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script|Roboto);
@import url(https://fonts.googleapis.com/css?family=Modak);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div#responsive-navbar-nav {
    padding-right: 10%;
}
@-webkit-keyframes rotation {
  to {
    transform: rotate(360deg) translateX(-50%) translateY(-50%);
  }
}
@keyframes rotation {
  to {
    transform: rotate(360deg) translateX(-50%) translateY(-50%);
  }
}

.bienvenidos {
  background: url(/static/media/fondo_noche.a4bf99d3.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: calc(120vh - 80px);
  padding: 2em;
  color: white;
  align-items: flex-end;
  padding-bottom: 5%;
  font-family: 'Bangers', cursive;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.bienvenidos .texto-bienvenidos {
  margin-top: .5rem;
}

.bienvenidos .texto-bienvenidos h2 {
  position: relative;
  z-index: 2;
  font-size: 5em;
  letter-spacing: 15px;
  text-transform: uppercase;
  text-shadow: 1px 1px #ac9939, 2px 2px #998833, 3px 3px #86772d, 4px 4px #82742b, 5px 5px #7e702a, 6px 6px #7a6d29, 7px 7px #776928, 8px 8px #736626, 9px 9px #6f6325, 10px 10px #6b5f24, 10px 10px 30px rgba(0, 0, 0, 0.7);
}

.bienvenidos2 {
  background: url(/static/media/fondo_dia.a504700c.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: calc(120vh - 80px);
  padding: 2em;
  color: white;
  align-items: flex-end;
  padding-bottom: 5%;
  font-family: 'Bangers', cursive;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.bienvenidos2 .texto-bienvenidos {
  margin-top: .5rem;
}

.bienvenidos2 .texto-bienvenidos h2 {
  position: relative;
  z-index: 2;
  font-size: 5em;
  letter-spacing: 15px;
  text-transform: uppercase;
  text-shadow: 1px 1px #ac9939, 2px 2px #998833, 3px 3px #86772d, 4px 4px #82742b, 5px 5px #7e702a, 6px 6px #7a6d29, 7px 7px #776928, 8px 8px #736626, 9px 9px #6f6325, 10px 10px #6b5f24, 10px 10px 30px rgba(0, 0, 0, 0.7);
}

@media (max-width: 1009px) {
  .bienvenidos, .bienvenidos2 {
    height: calc(100vh - 80px);
  }
  .bienvenidos .texto-bienvenidos h2,
  .bienvenidos .texto-bienvenido2 h2, .bienvenidos2 .texto-bienvenidos h2,
  .bienvenidos2 .texto-bienvenido2 h2 {
    font-size: 3em;
    letter-spacing: 7px;
  }
}
/*# sourceMappingURL=Home.css.map */
body {
  font-family: "Comic Sans MS", "Comic Sans";
}

p {
  font-family: Georgia, Cambria, Times, "Times New Roman";
}

/*
*************************************
        | COLORS
*************************************
*/
:root {
  --blue-navy: #05205d;
  --blue-light: #3155a7;
  --brown-leather: #ab987a;
  --orange-coral: #ff833d;
  --white-egg: #f5f5f5;
}

/*
*************************************
        | HEADER
*************************************
*/
.header-area .main-menu,
.header-area .main-menu,
.dropdown-menu {
  background: #ff833d;
  background: var(--orange-coral);
  box-shadow: 0 3px 16 0 rgba(0, 0, 0, 0.1);
}

.header-area .main-menu .navbar {
  height: 70px;
}

.header-area .main-menu .navbar .nav-link {
  font-size: 14px;
  color: #f5f5f5;
  color: var(--white-egg);
  padding: 8px;
}

.header-area .main-menu .navbar .nav-link:hover {
  font-weight: 600;
}

.main-info {
  position: relative;
  z-index: 1;
  padding-bottom: 42px;
  margin-bottom: 58px;
}

.main-info .container {
  border-radius: 12px;
  position: relative;
  bottom: -100px;
  background: #fff;
  padding-top: 28px;
  padding-bottom: 28px;
  box-shadow: 0 20px 80px 0 rgba(153, 153, 153, 0.3);
  margin-top: -100px;
}

.main-info .container .img-col .img-container > img {
  width: 90%;
  height: 80%;
}

.main-info .container .txt-col .text-container {
  margin: 20px auto 0 auto;
  width: 90%;
}

.main-info .container .txt-col .text-container h4 {
  font-size: 16px;
}

.main-info .container .txt-col .text-container p {
  font-size: 16px;
  color: #ab987a;
  color: var(--brown-leather);
  margin-bottom: 20px;
}

.main-info .container .txt-col .text-container ul.basic-info {
  padding-left: 0;
}

.main-info .container .txt-col .text-container ul.basic-info > li {
  margin-bottom: 8px;
}

.main-info .container .txt-col .text-container ul.basic-info > li > i {
  margin-right: 4px;
  color: #05205d;
  color: var(--blue-navy);
}

.main-info .container .txt-col .text-container ul.social-nw {
  padding-left: 0;
}

.main-info .container .txt-col .text-container ul.social-nw > li {
  margin-right: 20px;
}

.main-info .container .txt-col .text-container ul.social-nw > li i {
  font-size: 40px;
  color: #05205d;
  color: var(--blue-navy);
}

/*Skill Section */
#skills {
  padding-top: 150px;
}

.skill-item {
  margin-bottom: 24px;
}

.skill-item .progress .progress-bar {
  height: 15px;
  border-radius: 5px;
  vertical-align: middle;
  -ms-grid-row-align: center;
  align-self: center;
  /* The old syntax, deprecated, but still needed, prefixed, for Opera and WebKit-based browsers */
  background: -prefix-linear-gradient(left, orange, green, blue);
  /* The new syntax needed by standard-compliant browsers (IE 10 and Firefox 10 onwards), without prefix */
  background: linear-gradient(to right, orange, green, blue);
}

#portfolio {
  padding-top: 1px;
}

.card-container .card {
  max-width: 360px;
  margin-top: 16px;
}

.card-container .card .icon {
  font-size: 40px;
  color: #05205d;
  color: var(--blue-navy);
}

.card-container .card h5 {
  margin-top: 12px;
  margin-bottom: 8px;
  opacity: 0.8;
}

.main-info {
  padding-bottom: 42px;
  margin-bottom: 78px;
}

.main-info .container {
  bottom: -120px;
}

@media (min-width: 768px) {
  .header-area .main-menu .navbar .nav-link {
    font-size: 16px;
  }
}
/*# sourceMappingURL=Luciana.css.map */
.PageLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
.loader0 {

  background: url("/static/media/04-Brazil flag.8b293064.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader1 {

  background: url(/static/media/02-Brazil.28d93fdf.svg);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader2 {

  background: url(/static/media/05-Toucan.a821e5e2.svg);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader3 {

  background: url(/static/media/09-Drum.0652eea0.svg);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader4 {

  background: url(/static/media/12-redeemer.3f779dca.svg);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader5 {

  background: url(/static/media/13-Coffee.6eb75ef4.svg);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader6 {

  background: url("/static/media/17-Brazilian real.2532239b.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader7 {

  background: url("/static/media/25-Palm tree.bff41ac4.svg");
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}


@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.PageError {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}
body {
  background: #2196F3;
}

#titulo_categoria {
  text-align: center;
  width: 100%;
  margin: 0px auto;
  color: white;
  font-size: 40px;
  letter-spacing: 5px;
  font-family: 'Baloo Tamma', cursive;
  text-shadow: -1px -1px 0px #2196F3, 3px 3px 0px #2196F3, 6px 6px 0px #135EB9;
}

.form-group .form-control:focus,
.form-group .form-control:valid {
  color: white;
}

h3 {
  color: yellowgreen;
}

.card {
  min-width: 200px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.5), inset -5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px rgba(0, 0, 0, 0.5), -5px -5px 5px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
  /* HOVER */
}

.card .box {
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #ebf5fc;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  transition: 0.5s;
}

.card .box .content .card-header {
  display: flex;
  font-size: 2em;
  color: #3323F5;
  transition: 0.5s;
}

.card .box .content .card-header .link {
  justify-content: flex-end;
}

.card .box .content .card-body .card-title {
  font-size: 1.3em;
  z-index: 1;
  transition: 0.5s;
}

.card .box .content .card-body div {
  z-index: 1;
  transition: 0.5s;
}

.card .box .content .card-body ul li {
  list-style: none;
}

.card .box .content .card-footer .link {
  z-index: 1;
  transition: 0.5s;
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: #03a9f4;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.card:hover .box {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #fff, #F2EE5A);
}

.card:hover .box .content .card-header {
  color: #008030;
}

.card:hover .box .content .card-body .card-title {
  color: #3323F5;
}

.card:hover .box .content .card-footer .link {
  background: #9ECD5D;
}

@media (min-width: 1000px) {
  .modal-content {
    width: 200% !important;
    margin-left: -250px !important;
  }
}
/*# sourceMappingURL=Tarjeta.css.map */
.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.cls-10,
.cls-6 {
  opacity: 0.6;
}

.cls-7 {
  opacity: 0.4;
}

.cls-9 {
  fill: #f4b73b;
}

.cls-11 {
  fill: #f9c358;
}

.cls-12 {
  fill: #9b462c;
}

.cls-13 {
  fill: #aa512e;
}

.cls-14 {
  fill: #7d6aa5;
}

/* animations */
.wheel {
  -webkit-animation: wheel-rotate 6s ease infinite;
          animation: wheel-rotate 6s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes wheel-rotate {
  50% {
    transform: rotate(360deg);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
            animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  100% {
    transform: rotate(960deg);
  }
}

@keyframes wheel-rotate {
  50% {
    transform: rotate(360deg);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
            animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  100% {
    transform: rotate(960deg);
  }
}

.clock-hand-1 {
  -webkit-animation: clock-rotate 3s linear infinite;
          animation: clock-rotate 3s linear infinite;
  transform-origin: bottom;
  transform-box: fill-box;
}

.clock-hand-2 {
  -webkit-animation: clock-rotate 6s linear infinite;
          animation: clock-rotate 6s linear infinite;
  transform-origin: bottom;
  transform-box: fill-box;
}

@-webkit-keyframes clock-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes clock-rotate {
  100% {
    transform: rotate(360deg);
  }
}

#box-top {
  -webkit-animation: box-top-anim 2s linear infinite;
          animation: box-top-anim 2s linear infinite;
  transform-origin: right top;
  transform-box: fill-box;
}

@-webkit-keyframes box-top-anim {
  50% {
    transform: rotate(-5deg);
  }
}

@keyframes box-top-anim {
  50% {
    transform: rotate(-5deg);
  }
}

#umbrella {
  -webkit-animation: umbrella-anim 6s linear infinite;
          animation: umbrella-anim 6s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes umbrella-anim {
  25% {
    transform: translateY(10px) rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
}

@keyframes umbrella-anim {
  25% {
    transform: translateY(10px) rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
}

#cup {
  -webkit-animation: cup-rotate 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
          animation: cup-rotate 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
  transform-origin: top left;
  transform-box: fill-box;
}

@-webkit-keyframes cup-rotate {
  50% {
    transform: rotate(-5deg);
  }
}

@keyframes cup-rotate {
  50% {
    transform: rotate(-5deg);
  }
}

#pillow {
  -webkit-animation: pillow-anim 3s linear infinite;
          animation: pillow-anim 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes pillow-anim {
  25% {
    transform: rotate(10deg) translateY(5px);
  }
  75% {
    transform: rotate(-10deg);
  }
}

@keyframes pillow-anim {
  25% {
    transform: rotate(10deg) translateY(5px);
  }
  75% {
    transform: rotate(-10deg);
  }
}

#stripe {
  -webkit-animation: stripe-anim 3s linear infinite;
          animation: stripe-anim 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes stripe-anim {
  25% {
    transform: translate(10px, 0) rotate(-10deg);
  }
  75% {
    transform: translateX(10px);
  }
}

@keyframes stripe-anim {
  25% {
    transform: translate(10px, 0) rotate(-10deg);
  }
  75% {
    transform: translateX(10px);
  }
}

#bike {
  -webkit-animation: bike-anim 6s ease infinite;
          animation: bike-anim 6s ease infinite;
}

@-webkit-keyframes bike-anim {
  0% {
    transform: translateX(-1300px);
  }
  50% {
    transform: translateX(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
            animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    transform: translateX(1300px);
  }
}

@keyframes bike-anim {
  0% {
    transform: translateX(-1300px);
  }
  50% {
    transform: translateX(0);
    -webkit-animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
            animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    transform: translateX(1300px);
  }
}

#rucksack {
  -webkit-animation: ruck-anim 3s linear infinite;
          animation: ruck-anim 3s linear infinite;
  transform-origin: top;
  transform-box: fill-box;
}

@-webkit-keyframes ruck-anim {
  50% {
    transform: rotate(5deg);
  }
}

@keyframes ruck-anim {
  50% {
    transform: rotate(5deg);
  }
}

.circle {
  -webkit-animation: circle-anim ease infinite;
          animation: circle-anim ease infinite;
  transform-origin: center;
  transform-box: fill-box;
  perspective: 0px;
}

.circle.c1 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.circle.c2 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}

.circle.c3 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.circle.c4 {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.circle.c5 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.circle.c6 {
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
}

@-webkit-keyframes circle-anim {
  50% {
    transform: scale(0.2) rotateX(360deg) rotateY(360deg);
  }
}

@keyframes circle-anim {
  50% {
    transform: scale(0.2) rotateX(360deg) rotateY(360deg);
  }
}

.four,
#ou {
  -webkit-animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
          animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}

.four.a {
  transform-origin: bottom left;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  transform-box: fill-box;
}

.four.b {
  transform-origin: bottom right;
  -webkit-animation-duration: 3s;
          animation-duration: 3s;
  transform-box: fill-box;
}

#ou {
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  transform-origin: center;
  transform-box: fill-box;
}

@-webkit-keyframes four-anim {
  50% {
    transform: scale(0.98);
  }
}

@keyframes four-anim {
  50% {
    transform: scale(0.98);
  }
}
/*# sourceMappingURL=NotFound.css.map */
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }
  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

/**,
*:after,
*:before {
   box-sizing: border-box;
}
*/
body {
  font-family: 'Roboto', sans-serif;
}

.panda {
  position: relative;
  width: 200px;
  margin: 50px auto;
}

.face {
  width: 200px;
  height: 200px;
  background: #fff;
  border-radius: 100%;
  margin: 50px auto;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  z-index: 50;
  position: relative;
}

.ear, .ear:after {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #000;
  z-index: 5;
  border: 10px solid #fff;
  left: -15px;
  top: -15px;
  border-radius: 100%;
}

.ear:after {
  content: '';
  left: 125px;
}

.eye-shade {
  background: #000;
  width: 50px;
  height: 80px;
  margin: 10px;
  position: absolute;
  top: 35px;
  left: 25px;
  rotate: 220deg;
  border-radius: 25px / 20px 30px 35px 40px;
}

.eye-shade.rgt {
  rotate: 140deg;
  left: 105px;
}

.eye-white {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #fff;
  z-index: 500;
  left: 40px;
  top: 80px;
  overflow: hidden;
}

.eye-white.rgt {
  right: 40px;
  left: auto;
}

.eye-ball {
  position: absolute;
  width: 0px;
  height: 0px;
  left: 20px;
  top: 20px;
  max-width: 10px;
  max-height: 10px;
  transition: .1s;
}

.eye-ball:after {
  content: '';
  background: #000;
  position: absolute;
  border-radius: 100%;
  right: 0;
  bottom: 0px;
  width: 20px;
  height: 20px;
}

.nose {
  position: absolute;
  height: 20px;
  width: 35px;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50px 20px/ 30px 15px;
  rotate: 15deg;
  background: #000;
}

.body {
  background: #fff;
  position: absolute;
  top: 200px;
  left: -20px;
  border-radius: 100px 100px 100px 100px / 126px 126px 96px 96px;
  width: 250px;
  height: 282px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.hand, .hand:after, .hand:before {
  width: 40px;
  height: 30px;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  background: #000;
  margin: 5px;
  position: absolute;
  top: 70px;
  left: -25px;
}

.hand:after, .hand:before {
  content: '';
  left: -5px;
  top: 11px;
}

.hand:before {
  top: 26px;
}

.hand.rgt, .rgt.hand:after, .rgt.hand:before {
  left: auto;
  right: -25px;
}

.rgt.hand:after, .rgt.hand:before {
  left: auto;
  right: -5px;
}

.foot {
  top: 360px;
  left: -80px;
  position: absolute;
  background: #000;
  z-index: 1400;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 40px 40px 39px 40px / 26px 26px 63px 63px;
  width: 82px;
  height: 120px;
}

.foot:after {
  content: '';
  width: 55px;
  height: 65px;
  background: #222;
  border-radius: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
}

.foot .finger, .foot .finger:after, .foot .finger:before {
  position: absolute;
  width: 25px;
  height: 35px;
  background: #222;
  border-radius: 100%;
  top: 10px;
  right: 5px;
}

.foot .finger:after, .foot .finger:before {
  content: '';
  right: 30px;
  width: 20px;
  top: 0;
}

.foot .finger:before {
  right: 55px;
  top: 5px;
}

.foot.rgt {
  left: auto;
  right: -80px;
}

.foot.rgt .finger, .foot.rgt .finger:after, .foot.rgt .finger:before {
  left: 5px;
  right: auto;
}

.foot.rgt .finger:after {
  left: 30px;
  right: auto;
}

.foot.rgt .finger:before {
  left: 55px;
  right: auto;
}

form {
  display: none;
  max-width: 400px;
  padding: 20px 40px;
  background: #fff;
  height: 300px;
  margin: auto;
  display: block;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  transition: .3s;
  position: relative;
  margin-top: -100px;
  z-index: 500;
  border: 1px solid #eee;
  text-align: center;
}

form.up {
  margin-top: -180px;
}

h1 {
  color: #3B8004;
  font-family: 'Dancing Script', cursive;
}

.btn {
  background: #fff;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: 1px solid #3B8004;
  margin-top: 25px;
  cursor: pointer;
  transition: .3s;
  box-shadow: 0 50px #3B8004 inset;
  color: #fff;
}

.btn:hover {
  box-shadow: 0 0 #3B8004 inset;
  color: #3B8004;
}

.btn:focus {
  outline: none;
}

.form-group {
  position: relative;
  font-size: 15px;
  color: #666;
}

.form-group + .form-group {
  margin-top: 30px;
}

.form-group .form-label {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 5px;
  transition: .3s;
}

.form-group .form-control {
  width: 100%;
  position: relative;
  z-index: 3;
  height: 35px;
  background: none;
  border: none;
  padding: 5px 0;
  transition: .3s;
  border-bottom: 1px solid #777;
  color: #555;
}

.form-group .form-control:invalid {
  outline: none;
}

.form-group .form-control:focus, .form-group .form-control:valid {
  outline: none;
  box-shadow: 0 1px #3B8004;
  border-color: #3B8004;
}

.form-group .form-control:focus + .form-label, .form-group .form-control:valid + .form-label {
  font-size: 12px;
  color: #3B8004;
  margin-top: -15px;
}

.alert {
  position: absolute;
  color: #f00;
  font-size: 16px;
  right: -180px;
  top: -300px;
  z-index: 200;
  padding: 30px 25px;
  background: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0;
  scale: 0;
  transition: .4s .6s linear;
}

.alert:after, .alert:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  background: #fff;
  left: -19px;
  bottom: -8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.alert:before {
  width: 15px;
  height: 15px;
  left: -35px;
  bottom: -25px;
}

.wrong-entry {
  -webkit-animation: wrong-log 0.3s;
          animation: wrong-log 0.3s;
}

.wrong-entry .alert {
  opacity: 1;
  scale: 1;
}

@-webkit-keyframes eye-blink {
  to {
    height: 30px;
  }
}

@keyframes eye-blink {
  to {
    height: 30px;
  }
}

@-webkit-keyframes wrong-log {
  0%,
  100% {
    left: 0px;
  }
  20%,
  60% {
    left: 20px;
  }
  40%,
  80% {
    left: -20px;
  }
}

@keyframes wrong-log {
  0%,
  100% {
    left: 0px;
  }
  20%,
  60% {
    left: 20px;
  }
  40%,
  80% {
    left: -20px;
  }
}
/*# sourceMappingURL=Login.css.map */
@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }
  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

/**,
*:after,
*:before {
   box-sizing: border-box;
}
*/
body {
  font-family: 'Roboto', sans-serif;
}

.panda {
  position: relative;
  width: 200px;
  margin: 50px auto;
}

.face {
  width: 200px;
  height: 200px;
  background: #fff;
  border-radius: 100%;
  margin: 50px auto;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  z-index: 50;
  position: relative;
}

.ear, .ear:after {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #000;
  z-index: 5;
  border: 10px solid #fff;
  left: -15px;
  top: -15px;
  border-radius: 100%;
}

.ear:after {
  content: '';
  left: 125px;
}

.eye-shade {
  background: #000;
  width: 50px;
  height: 80px;
  margin: 10px;
  position: absolute;
  top: 35px;
  left: 25px;
  rotate: 220deg;
  border-radius: 25px / 20px 30px 35px 40px;
}

.eye-shade.rgt {
  rotate: 140deg;
  left: 105px;
}

.eye-white {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #fff;
  z-index: 500;
  left: 40px;
  top: 80px;
  overflow: hidden;
}

.eye-white.rgt {
  right: 40px;
  left: auto;
}

.eye-ball {
  position: absolute;
  width: 0px;
  height: 0px;
  left: 20px;
  top: 20px;
  max-width: 10px;
  max-height: 10px;
  transition: .1s;
}

.eye-ball:after {
  content: '';
  background: #000;
  position: absolute;
  border-radius: 100%;
  right: 0;
  bottom: 0px;
  width: 20px;
  height: 20px;
}

.nose {
  position: absolute;
  height: 20px;
  width: 35px;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50px 20px/ 30px 15px;
  rotate: 15deg;
  background: #000;
}

.body {
  background: #fff;
  position: absolute;
  top: 200px;
  left: -20px;
  border-radius: 100px 100px 100px 100px / 126px 126px 96px 96px;
  width: 250px;
  height: 282px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.hand, .hand:after, .hand:before {
  width: 40px;
  height: 30px;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  background: #000;
  margin: 5px;
  position: absolute;
  top: 70px;
  left: -25px;
}

.hand:after, .hand:before {
  content: '';
  left: -5px;
  top: 11px;
}

.hand:before {
  top: 26px;
}

.hand.rgt, .rgt.hand:after, .rgt.hand:before {
  left: auto;
  right: -25px;
}

.rgt.hand:after, .rgt.hand:before {
  left: auto;
  right: -5px;
}

.foot {
  top: 360px;
  left: -80px;
  position: absolute;
  background: #000;
  z-index: 1400;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 40px 40px 39px 40px / 26px 26px 63px 63px;
  width: 82px;
  height: 120px;
}

.foot:after {
  content: '';
  width: 55px;
  height: 65px;
  background: #222;
  border-radius: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
}

.foot .finger, .foot .finger:after, .foot .finger:before {
  position: absolute;
  width: 25px;
  height: 35px;
  background: #222;
  border-radius: 100%;
  top: 10px;
  right: 5px;
}

.foot .finger:after, .foot .finger:before {
  content: '';
  right: 30px;
  width: 20px;
  top: 0;
}

.foot .finger:before {
  right: 55px;
  top: 5px;
}

.foot.rgt {
  left: auto;
  right: -80px;
}

.foot.rgt .finger, .foot.rgt .finger:after, .foot.rgt .finger:before {
  left: 5px;
  right: auto;
}

.foot.rgt .finger:after {
  left: 30px;
  right: auto;
}

.foot.rgt .finger:before {
  left: 55px;
  right: auto;
}

form {
  display: none;
  max-width: 400px;
  padding: 20px 40px;
  background: #fff;
  height: 300px;
  margin: auto;
  display: block;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  transition: .3s;
  position: relative;
  margin-top: -100px;
  z-index: 500;
  border: 1px solid #eee;
  text-align: center;
}

form.up {
  margin-top: -180px;
}

h1 {
  color: #3B8004;
  font-family: 'Dancing Script', cursive;
}

.btn {
  background: #fff;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: 1px solid #3B8004;
  margin-top: 25px;
  cursor: pointer;
  transition: .3s;
  box-shadow: 0 50px #3B8004 inset;
  color: #fff;
}

.btn:hover {
  box-shadow: 0 0 #3B8004 inset;
  color: #3B8004;
}

.btn:focus {
  outline: none;
}

.form-group {
  position: relative;
  font-size: 15px;
  color: #666;
}

.form-group + .form-group {
  margin-top: 30px;
}

.form-group .form-label {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 5px;
  transition: .3s;
}

.form-group .form-control {
  width: 100%;
  position: relative;
  z-index: 3;
  height: 35px;
  background: none;
  border: none;
  padding: 5px 0;
  transition: .3s;
  border-bottom: 1px solid #777;
  color: #555 !important;
}

.form-group .form-control:invalid {
  outline: none;
}

.form-group .form-control:focus, .form-group .form-control:valid {
  outline: none;
  box-shadow: 0 1px #3B8004;
  border-color: #3B8004;
}

.form-group .form-control:focus + .form-label, .form-group .form-control:valid + .form-label {
  font-size: 12px;
  color: #3B8004;
  margin-top: -15px;
}

.alert {
  position: absolute;
  color: #f00;
  font-size: 16px;
  right: -180px;
  top: -300px;
  z-index: 200;
  padding: 30px 25px;
  background: #fff;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0;
  scale: 0;
  transition: .4s .6s linear;
}

.alert:after, .alert:before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  background: #fff;
  left: -19px;
  bottom: -8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.alert:before {
  width: 15px;
  height: 15px;
  left: -35px;
  bottom: -25px;
}

.wrong-entry {
  -webkit-animation: wrong-log 0.3s;
          animation: wrong-log 0.3s;
}

.wrong-entry .alert {
  opacity: 1;
  scale: 1;
}

@-webkit-keyframes eye-blink {
  to {
    height: 30px;
  }
}

@keyframes eye-blink {
  to {
    height: 30px;
  }
}

@-webkit-keyframes wrong-log {
  0%,
  100% {
    left: 0px;
  }
  20%,
  60% {
    left: 20px;
  }
  40%,
  80% {
    left: -20px;
  }
}

@keyframes wrong-log {
  0%,
  100% {
    left: 0px;
  }
  20%,
  60% {
    left: 20px;
  }
  40%,
  80% {
    left: -20px;
  }
}
/*# sourceMappingURL=Login.css.map */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Navbar {
  font-family: 'Baloo Tamma', cursive;
  background-color: #9ECD5D !important;
  border-radius: 0;
  margin-bottom: 0;
}

.Navbar .text {
  display: inline-block;
  font-size: 20px;
  line-height: 84%;
  font-family: Modak;
  padding-left: 7px;
  -webkit-background-clip: text;
          background-clip: text;
  color: transparent;
  text-shadow: 7px 7px 0px #d2d2d22e;
}

.Navbar .amarillo {
  color: yellow;
}

.Navbar .verde {
  color: green;
}

.Navbar .azul {
  color: blue;
}

.Navbar .ml-auto #collasible-nav-dropdown, .Navbar .ml-auto .nav-link {
  margin-right: 30px;
  color: yellow;
}

.Navbar .ml-auto #collasible-nav-dropdown:hover, .Navbar .ml-auto .nav-link:hover {
  color: white;
}

.Navbar .ml-auto .link {
  text-decoration: none;
  margin-right: 2em;
}

.Navbar .link {
  text-decoration: none;
}

@media (min-width: 1000px) {
  .Navbar .text {
    font-size: 40px;
  }
}

@media (max-width: 1009px) {
  a.link.dropdown-item {
    padding-left: 10px;
  }
  .dropdown-menu.show {
    max-width: 90%;
  }
}
/*# sourceMappingURL=Navbar.css.map */
